import React from "react";

export default function MedarbetareItem({ post }) {
  const { title, medarbetareInformation } = post;
  return (
    <div className="flex flex-col  w-full h-full">
      <h2 className="text-xl font-bold">{title}</h2>
      <h3 className="font-medium">{medarbetareInformation.roll}</h3>
      <div>
        <p>{medarbetareInformation.epost}</p>
        <p>{medarbetareInformation.telefon}</p>
      </div>
    </div>
  );
}

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import MedarbetareItem from "./MedarbetareItem";

export default function Medarbetare() {
  const data = useStaticQuery(graphql`
    {
      allWpMedarbetaren {
        nodes {
          title
          medarbetareInformation {
            roll
            telefon
            epost
          }
        }
      }
    }
  `);
  const posts = data.allWpMedarbetaren.nodes;
  return (
    <div className="grid md:grid-cols-2 gap-4">
      {posts.map((post) => {
        return <MedarbetareItem key={post.id} post={post} />;
      })}
    </div>
  );
}

import React from "react";
import Medarbetare from "../components/kontakt/Medarbetare";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Körplåt`, `Malmö`, `Kontakt`, `Telefon`]}
        title="Kontakt"
      />
      <section className="max-w-4xl mx-auto my-16 px-8 md:px-0">
        <Medarbetare />
      </section>
      <section className="max-w-4xl mx-auto my-16">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              width="100%"
              height="600"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=J%C3%B6rgen%20Kocksgatan%2075&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            ></iframe>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
